const advanceCookieElement = document.querySelector('.advance-cookie-banner');

if (advanceCookieElement) {
  const cookieInputs = document.querySelectorAll('.advance-cookie-banner__modal input');
  const essentialCookie = document.querySelectorAll('.is-necessary input');
  const cookieFooter = document.querySelector('.footer__bottom-nav .btn--cookie-setting');

  if (!Cookies.get('necessary_cookie')) {
    advanceCookieElement.classList.add('cookie-not-set');
    if (cookieFooter) {
      cookieFooter.classList.add('cookie-not-set');
    }
  }
  const activeScripts = () => {
    try {
      const cookieValue = Cookies.get('performance_cookie');
      if (cookieValue && cookieValue === 'true') {
        document.querySelectorAll('script.performance_cookie').forEach((item) => {
          item.type = 'text/javascript';
          document.head.appendChild(item);
        });
        advanceCookieElement.querySelectorAll('.advance-cookie-banner__modal-content input').forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          if (item.dataset.cookie === 'performance_cookie') {
            item.checked = item.dataset.cookie;
          }
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const setCookie = (cookieName) => {
    Cookies.set(cookieName, true, { expires: 365 });
  };

  const closeCookieModal = () => {
    const modalEle = document.querySelector('.advance-cookie-banner__modal.is-open-modal');
    if (modalEle) {
      modalEle.classList.remove('is-open-modal');
      toggleBodyScrollLock(false);
    }
  };
  const toggleCookieInput = (elem, toggle) => {
    // eslint-disable-next-line no-param-reassign
    elem.checked = toggle;
  };

  // *******************************************************************************
  // Set cookies
  // *******************************************************************************

  attachEvent('.btn--accept-cookie, .btn--confirm-choice', 'click', (ev) => {
    if (ev.target.classList.contains('btn--accept-cookie')) {
      cookieInputs.forEach((item) => {
        const cookieName = item.getAttribute('data-cookie');
        setCookie(cookieName);
        toggleCookieInput(item, true);
      });
    } else {
      cookieInputs.forEach((item) => {
        const cookieName = item.getAttribute('data-cookie');
        if (item.checked) {
          setCookie(cookieName);
        } else {
          toggleCookieInput(item, false);
          Cookies.remove(cookieName);
        }
      });
    }
    ev.preventDefault();
    advanceCookieElement.classList.remove('cookie-not-set');
    closeCookieModal();
    setTimeout(() => {
      activeScripts();
    }, 200);
  });

  // *******************************************************************************
  // Accept all cookies
  // *******************************************************************************

  attachEvent('.btn--accept-all', 'click', (ev) => {
    cookieInputs.forEach((item) => {
      const cookieName = item.getAttribute('data-cookie');
      setCookie(cookieName);
      toggleCookieInput(item, true);
    });
    ev.preventDefault();
    closeCookieModal();
    advanceCookieElement.classList.remove('cookie-not-set');
    activeScripts();
  });

  // *******************************************************************************
  // Check already set cookie on load
  // *******************************************************************************
  cookieInputs.forEach((item) => {
    const cookieName = item.getAttribute('data-cookie');
    const cookieStatus = Cookies.get(cookieName);

    // eslint-disable-next-line no-param-reassign
    toggleCookieInput(item, !!cookieStatus);
  });
  essentialCookie.forEach((input) => {
    toggleCookieInput(input, true);
  });

  // *******************************************************************************
  // Open Modal
  // *******************************************************************************

  attachEvent('.btn--cookie-setting:not(.cookie-not-set)', 'click', (ev) => {
    ev.preventDefault();
    const parentElem = document.querySelector('.advance-cookie-banner');
    parentElem.querySelector('.advance-cookie-banner__modal').classList.add('is-open-modal');
    toggleBodyScrollLock(true);
  });

  // *******************************************************************************
  // Close Modal
  // *******************************************************************************
  attachEvent(
    '.advance-cookie-modal__close-btn, .advance-cookie-banner__modal-overlay, .advance-cookie-banner__modal-close',
    'click',
    (ev) => {
      ev.target.closest('.advance-cookie-banner__modal').classList.remove('is-open-modal');
      toggleBodyScrollLock(false);
      ev.preventDefault();
    },
  );

  // Close bar on click of 'X'
  attachEvent('.advance-cookie-banner__close', 'click', (ev) => {
    ev.target.closest('.advance-cookie-banner').classList.remove('cookie-not-set');
    ev.preventDefault();
  });

  // *******************************************************************************
  // Close Modal When Escape Key Press
  // *******************************************************************************

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      closeCookieModal();
    }
  });
  activeScripts();
}
