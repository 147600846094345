// *******************************************************************************
// 1.0 - Open dropdown
// *******************************************************************************

// Open dropdown
attachEvent('.dropdown__placeholder', 'click', (placeholder) => {
  const parent = placeholder.target.closest('.dropdown');
  parent.classList.toggle('is-open');

  if (parent.classList.contains('dropdown--search')) {
    parent.querySelector('.dropdown__search-input').focus();
  }
});

// Close on click outside
document.addEventListener('click', (ev) => {
  const dropdowns = document.querySelectorAll('.dropdown');

  dropdowns.forEach((dropdown) => {
    const clickInside = dropdown.contains(ev.target);
    const dropdownSearchInput = dropdown.querySelector('.dropdown__search-input');
    if (!clickInside) {
      dropdown.classList.remove('is-open');
      dropdownSearchInput?.blur();
    }
  });
});

// Pick option
const getHash = () => window.location.hash.replace(/^#/, '');
attachEvent('.dropdown__list-option', 'click', (dropdownOption) => {
  const dropdownParent = dropdownOption.target.closest('.dropdown');
  const dropdownPlaceholderText = dropdownParent.querySelector('.dropdown__placeholder-text');

  if (!dropdownParent.classList.contains('dropdown--multiselect')) {
    // Change text
    const dropdownOptionText = dropdownOption.target.innerHTML;
    const dropdownOptionValue = dropdownOption.target.dataset.ehrm;
    dropdownPlaceholderText.innerHTML = dropdownOptionText;
    dropdownPlaceholderText.dataset.ehrm = dropdownOptionValue;
  }

  // Close the dropdown
  if (!dropdownParent.classList.contains('dropdown--multiselect')) {
    dropdownParent.classList.remove('is-open');
  }
  dropdownParent.classList.add('is-selected');
});

// *******************************************************************************
// 3.0 - Open a specific dropdown based on has in url
// *******************************************************************************

document.addEventListener('DOMContentLoaded', () => {
  const locationHash = getHash();

  if (locationHash) {
    const selectedElem = document.querySelectorAll(`#${locationHash}`);

    // Loop throught elements to avoid errors if there is no element to be found
    selectedElem.forEach((el) => {
      // Set active selection
      el.setAttribute('checked', 'true');

      // Change text
      const dropdownParent = el.closest('.dropdown');
      if (dropdownParent) {
        const optionLabel = el.parentElement.querySelector('.dropdown__list-option');
        const dropdownPlaceholder = dropdownParent.querySelector('.dropdown__placeholder');
        dropdownPlaceholder.innerHTML = optionLabel.innerHTML;
      }
    });
  }
});

// *******************************************************************************
// Dropdown Search
// *******************************************************************************

const dropdownSearch = document.querySelectorAll('.dropdown__search-input');

dropdownSearch.forEach((input) => {
  const parentElem = input.closest('.dropdown');

  const filterCountries = (input) => {
    const searchValue = input.value.toLowerCase();
    const countryOptions = parentElem.querySelectorAll('.dropdown__list-option');

    Array.from(countryOptions).forEach((option) => {
      const country = option.innerHTML.toLowerCase();
      option.closest('.dropdown__list-item').style.display = country.includes(searchValue) ? 'block' : 'none';
    });
  };

  input.addEventListener('input', () => filterCountries(input));
});

if (dropdownSearch.length > 0) {
  // Event listener to hide the list when clicking outside a dropdown
  document.addEventListener('click', () => {
    document.querySelectorAll('.dropdown__list-item').forEach((option) => {
      const input = option.closest('.dropdown').querySelector('.dropdown__search-input');
      option.style.display = 'block';
      input.value = '';
    });
  });
}
