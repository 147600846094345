// Quiz slider
const quizSlider = document.querySelectorAll('[data-slider="quiz-module__slider"]');
let quizSliderElem;

const quizSliderOptions = {
  slidesPerView: 1,
  spaceBetween: 0,
  speed: 1500,
  allowTouchMove: false,
  autoHeight: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  effect: 'creative',
  creativeEffect: {
    prev: {
      translate: ['-150%', 0, -400],
    },
    next: {
      translate: ['150%', 0, -400],
    },
  },
  breakpoints: {
    1023: {
      autoHeight: false,
    },
  },
};

quizSlider.forEach((el) => {
  const parentElem = el.closest('.quiz-module__body');

  const arrowLeft = parentElem.querySelector('.swiper-button-prev');
  const arrowRight = parentElem.querySelector('.swiper-button-next');

  quizSliderOptions.navigation.prevEl = arrowLeft;
  quizSliderOptions.navigation.nextEl = arrowRight;

  quizSliderElem = new Swiper(el, quizSliderOptions);
});

const paginationContainers = document.querySelectorAll('.quiz-module__item-pagination');
const answersInputs = document.querySelectorAll('.quiz-module__item input');

answersInputs.forEach((input) => {
  input.addEventListener('change', (el) => {
    const parent = el.target.closest('.quiz-module__item');
    const slideIndex = parent.getAttribute('data-item');
    paginationContainers.forEach((container) => {
      const bullets = container.querySelectorAll('.quiz-module__item-pagination-text');
      bullets[slideIndex].classList.add('is-completed');
    });
  });
});

paginationContainers.forEach((container) => {
  const bullets = container.querySelectorAll('.quiz-module__item-pagination-text');

  bullets.forEach((bullet, index) => {
    bullet.addEventListener('click', () => {
      if (bullet.classList.contains('is-completed') || bullet.classList.contains('is-active')) {
        quizSliderElem.slideTo(index, 1500);
      }
    });
  });
});

// Validation check function
const checkValidation = (element) => {
  const parent = element.closest('.quiz-module__item-right');
  const radioButtons = parent.querySelectorAll('.radio-button input');
  const isAnyChecked = Array.from(radioButtons).some((radio) => radio.checked);
  parent.classList.toggle('is-validation-failed', !isAnyChecked);
  return isAnyChecked;
};

attachEvent('.quiz-module__next-btn', 'click', (btn) => {
  const isValid = checkValidation(btn.target);
  if (isValid) {
    if (windowWidth() < breakpointMd) {
      quizSliderElem.slideNext(800);
    } else {
      quizSliderElem.slideNext(1200);
    }
  }
});

attachEvent('.quiz-module__submit', 'click', (btn) => {
  const parent = btn.target.closest('.quiz-module');
  const inputs = parent.querySelectorAll('.quiz-module__inputs input:checked');
  const isValid = checkValidation(btn.target);

  if (isValid) {
    // Create a frequency map to count selected options
    const frequency = {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    };

    // Map result attributes to A, B, C, D
    const resultMap = {
      financni_varcevalec: 'A',
      financni_uzivac: 'B',
      financni_vlagatelj: 'C',
      financnega_podjetnik: 'D',
    };

    // Count the selected results
    inputs.forEach((input) => {
      const { result } = input.dataset; // Get the data-result attribute
      if (resultMap[result]) {
        frequency[resultMap[result]]++;
      }
    });

    // Determine the most selected answer
    let maxFrequency = 0;
    let selectedResult = '';

    for (const [key, value] of Object.entries(frequency)) {
      if (value > maxFrequency) {
        maxFrequency = value;
        selectedResult = key;
      }
    }

    // Reverse map selectedResult back to the original data-result value
    const reverseResultMap = Object.fromEntries(Object.entries(resultMap).map(([key, value]) => [value, key]));
    const resultDataValue = reverseResultMap[selectedResult];

    // Open the result slider
    const modal = parent.querySelector('.form-modal');
    const resultSlider = parent.querySelector(`[data-result-modal="${resultDataValue}"]`);

    modal?.classList.add('is-open-modal');
    resultSlider?.classList.add('is-open');

    // Optionally store the result in cookies
    const resultsMapToText = {
      A: 'Varčevalec',
      B: 'Uživač',
      C: 'Vlagatelj',
      D: 'Podjetnik',
    };
    Cookies.set('vzgoja_otroka_result', resultsMapToText[selectedResult]);
  }
});

attachEvent('.btn--retry', 'click', (event) => {
  const quizModule = event.target.closest('.quiz-module');

  // Get the modal and its form
  const inquiryModal = quizModule.querySelector('.form-modal');
  const inquiryModalForm = inquiryModal.querySelector('form');

  // Reset the quiz slider to the first slide
  quizSliderElem.slideTo(0, 800);

  // Close the modal and reset the form
  inquiryModal.classList.remove('is-open-modal');
  inquiryModalForm.reset();

  // Reset validation and checked inputs for all quiz questions
  const quizQuestions = quizModule.querySelectorAll('.quiz-module__inputs');
  quizQuestions.forEach((question) => {
    const checkedInput = question.querySelector('input:checked');
    question.classList.remove('is-validation-failed');
    if (checkedInput) {
      checkedInput.checked = false;
    }
  });
});
