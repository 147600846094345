// Quiz slider
const resultSlider = document.querySelectorAll('[data-slider="result-slider"]');

if (resultSlider.length > 0) {
  const resultSliderOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 800,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoHeight: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet(index, className) {
        const formattedIndex = (index + 1).toString().padStart(2, '0');
        return `<span class="${className}">${formattedIndex}</span>`;
      },
    },
    effect: 'creative',
    creativeEffect: {
      prev: {
        translate: ['-120%', 0, -400],
      },
      next: {
        translate: ['120%', 0, -400],
      },
    },
    breakpoints: {
      1023: {
        autoHeight: false,
        speed: 1200,
      },
    },
  };

  resultSlider.forEach((el) => {
    const parentElem = el.closest('.result-slider');

    const arrowLeft = parentElem.querySelector('.swiper-button-prev');
    const arrowRight = parentElem.querySelector('.swiper-button-next');
    const paginationElem = parentElem.querySelector('.swiper-pagination');

    resultSliderOptions.navigation.prevEl = arrowLeft;
    resultSliderOptions.navigation.nextEl = arrowRight;
    resultSliderOptions.pagination.el = paginationElem;

    const resultSliderInit = new Swiper(el, resultSliderOptions);

    const prevArrow = parentElem.querySelector('.result-slider__pagination-arrow.left-arrow');
    const nextArrow = parentElem.querySelector('.result-slider__pagination-arrow.right-arrow');
    const resultSlides = parentElem.querySelectorAll('.result-slider .swiper-slide');
    const nextButtons = parentElem.querySelectorAll('.btn-next-result');
    const retryButtons = parentElem.querySelectorAll('.btn--retry');
    const accordionButtons = parentElem.querySelectorAll('.accordion-single__title');
    const tabLinks = parentElem.querySelectorAll('.tabpanel__tab-link');

    prevArrow.addEventListener('click', () => {
      resultSliderInit.slidePrev(800);
    });

    nextArrow.addEventListener('click', () => {
      resultSliderInit.slideNext(800);
    });

    resultSliderInit.on('slideChange', (swiper) => {
      prevArrow.classList.toggle('is-disabled', swiper.activeIndex === 0);
      nextArrow.classList.toggle('is-disabled', swiper.activeIndex === resultSlides.length - 1);
      const activeSlide = resultSlides[swiper.activeIndex];
      const slideEl = activeSlide.querySelector('.result-screen');
      parentElem.classList.toggle('revert-arrow', slideEl.classList.contains('result-screen--reverse'));
    });

    retryButtons.forEach((btn) => {
      btn.addEventListener('click', (ev) => {
        resultSliderInit.slideTo(0, 0);
        parentElem.classList.remove('is-open');
        ev.preventDefault();
      });
    });

    nextButtons.forEach((btn) => {
      btn.addEventListener('click', () => {
        resultSliderInit.slideNext(800);
      });
    });

    [...tabLinks, ...accordionButtons].forEach((btn) => {
      btn.addEventListener('click', () => {
        setTimeout(() => {
          resultSliderInit.updateAutoHeight(300);
        }, 310);
      });
    });
  });
}
